import React from "react";
import loadable from "@loadable/component";

const ArticlesArticleQuery = loadable(() =>
  import("@dynamicQueries/ArticlesArticleQuery")
);
const ArticlesWhitepaperQuery = loadable(() =>
  import("@dynamicQueries/ArticlesWhitepaperQuery")
);
const CareersDefaultQuery = loadable(() =>
  import("@dynamicQueries/CareersDefaultQuery")
);
const CaseStudiesDefaultQuery = loadable(() =>
  import("@dynamicQueries/CaseStudiesDefaultQuery")
);
const FormPageDefaultQuery = loadable(() =>
  import("@dynamicQueries/FormPageDefaultQuery")
);
const ModulesDefaultQuery = loadable(() =>
  import("@dynamicQueries/ModulesDefaultQuery")
);
const PageBasicQuery = loadable(() => import("@dynamicQueries/PageBasicQuery"));
const PageDefaultQuery = loadable(() =>
  import("@dynamicQueries/PageDefaultQuery")
);
const SolutionsDefaultQuery = loadable(() =>
  import("@dynamicQueries/SolutionsDefaultQuery")
);

const Preview = props => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // to populate these, append ?type={type.handle}&section={section.handle} to the preview targets
  const type = urlParams.get("type");
  const section = urlParams.get("section");

  if (section === "articles") {
    if (type === "article") return <ArticlesArticleQuery {...props} />;
    if (type === "whitepaper") return <ArticlesWhitepaperQuery {...props} />;
  }
  if (section === "page") {
    if (type === "default") return <PageDefaultQuery {...props} />;
    if (type === "basic") return <PageBasicQuery {...props} />;
  }
  if (section === "careers") return <CareersDefaultQuery {...props} />;
  if (section === "caseStudies") return <CaseStudiesDefaultQuery {...props} />;
  if (section === "formPage") return <FormPageDefaultQuery {...props} />;
  if (section === "modules") return <ModulesDefaultQuery {...props} />;
  if (section === "solutions") return <SolutionsDefaultQuery {...props} />;

  return <div {...props} />;
};

export default Preview;
